
<template>
    <SeoMetaTags />
    <div class="home-page" v-bind:[attributes.itemId]="page.system.id">
        <HomeHero v-if="page.elements.hero.value.length > 0"
            :model="pageStore.getLinkedItem<HomepageHeroModel>(page.elements.hero.value[0])"
            v-bind:[attributes.elementCodename]="contentTypes.homepage.elements.hero.codename" />
        <RichText :value="page.elements.content.value"
            v-bind:[attributes.elementCodename]="contentTypes.homepage.elements.content.codename" />
    </div>
</template>
<script setup lang="ts">
import { type HomepageHeroModel, type HomepageModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();
const pageStore = usePageStore()
await pageStore.fetchHome()
const page = pageStore.page as HomepageModel
</script>